var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-text-input"},[(_vm.header || _vm.percentage)?_c('div',{staticClass:"d-flex justify-space-between align-center flex-nowrap"},[(_vm.header)?_c('div',{class:_vm.headerClass || 'field__subheader'},[(_vm.required)?_c('span',{staticClass:"field__subheader--required"},[_vm._v(" * ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.header)+" ")]):_vm._e(),(_vm.percentage && _vm.$vuetify.breakpoint.xs)?_c('div',{staticClass:"field__percentage"},[_vm._v(" "+_vm._s(_vm.percentage)+" ")]):_vm._e()]):_vm._e(),_c('validation-observer',{ref:"textInput"},[_c('validation-provider',{attrs:{"rules":{
        max: _vm.maxlength || false,
        min: _vm.minlength || false,
        passmin: _vm.passmin || false,
        required: _vm.required,
        password: _vm.password_to_compare || false,
        email: _vm.email,
        decimal: _vm.decimal,
      },"mode":_vm.mode,"immediate":_vm.immediate},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"mt-4",attrs:{"readonly":_vm.readonly,"placeholder":_vm.placeholder,"dense":"","outlined":"","error-messages":errors,"value":_vm.value,"type":_vm.type || null,"hint":_vm.hint,"persistent-hint":!!_vm.hint,"rules":_vm.rules,"autocomplete":_vm.autocomplete},on:{"input":(text) => _vm.$emit('input', text),"change":(text) => _vm.$emit('change', text.trim()),"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return ((text) => _vm.$emit('enter', text)).apply(null, arguments)},"focus":function($event){return _vm.$emit('focus')}}})]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }