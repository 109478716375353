var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"mt-5 mb-15 px-10",attrs:{"fluid":""}},[_c('PageHeader',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/icons/google_analytics-horizontal.svg"),"alt":"google analytic icon","width":"400"}})]},proxy:true},{key:"subheader",fn:function(){return [_c('div',{staticClass:"d-flex"},[_c('CustomDateRangePicker',{staticClass:"ml-auto",attrs:{"hide-details":"","max-date":new Date()},model:{value:(_vm.dateRanges),callback:function ($$v) {_vm.dateRanges=$$v},expression:"dateRanges"}})],1)]},proxy:true}])}),_c('v-container',{staticClass:"mt-4",style:({
      height: _vm.dynamic_height ? _vm.dynamic_height + 'px' : 'auto',
    }),attrs:{"fluid":""}},[_c('v-row',{staticClass:"grid-stack grid-stack-4",staticStyle:{"z-index":"7"}},_vm._l((_vm.report_widgets),function(report_widget){return _c('div',{key:'report-widget-' + report_widget.id,class:{
          'grid-stack-item': true,
          'ui-draggable-disabled': true,
          'ui-resizable-disabled': true,
        },attrs:{"id":'report-widget-' + report_widget.id,"gs-x":report_widget.xPosition,"gs-y":report_widget.yPosition,"gs-w":report_widget.width,"gs-h":report_widget.height}},[_c('div',{staticClass:"grid-stack-item-content"},[_c(report_widget.renderComponentName,{tag:"component",staticClass:"item-component",attrs:{"config":report_widget,"data":report_widget.data,"vertical":""}})],1)])}),0)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }