var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mb-3"},[_c('CustomDropdown',{staticClass:"mt--2",attrs:{"autocomplete":"","header":"Vendor","placeholder":"Select an Option","value":_vm.configuration.vendor,"items":_vm.vendors,"readonly":_vm.vendors.length === 1,"required":"","item-value":"id","item-text":"name"},on:{"change":function($event){return _vm.$emit('update:configuration', {
          ..._vm.configuration,
          vendor: $event.id,
        })}}})],1),_c('div',{staticClass:"mb-3"},[_c('CustomTextarea',{attrs:{"header":"Message Body","placeholder":"Enter the message body","value":_vm.configuration.messageBody,"hint":_vm.messageBodyHint,"required":""},on:{"input":function($event){return _vm.$emit('update:configuration', {
          ..._vm.configuration,
          messageBody: $event,
        })}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }