<template>
  <v-container class="pa-0" fluid>
    <v-row class="d-flex mt-8">
      <v-col cols="12">
        <div class="title-text mt-4 mb-4">
          Engagement Metrics
        </div>
        <div class="d-flex flex-wrap justify-center">
          <div class="d-flex statistic flex-wrap mb-4 flex-grow-1">
            <div class="d-flex flex-column flex-sm-row align-center align-sm-start mb-2">
              <v-icon size="22" color="#eba42b" class="mr-2 ml-2 ml-sm-0 pt-1">
                mdi-star
              </v-icon>
              <div class="d-flex flex-column align-center align-sm-start">
                <div class="metric-score metric-score--gold">
                  {{ contact_statistics && contact_statistics.contactStatistics ? contact_statistics.contactStatistics.leadScore : 0 }}
                </div>
                <div class="point-text point-text--regular">
                  Lead Score
                </div>
              </div>
            </div>
            <div class="d-flex flex-column flex-sm-row align-center align-sm-start mb-2">
              <v-icon size="22" color="#2b84eb" class="mr-2 ml-2 ml-sm-0 pt-1">
                mdi-fingerprint
              </v-icon>
              <div class="d-flex flex-column align-center align-sm-start">
                <div class="metric-score">
                  {{ touchpoints }}
                </div>
                <div class="point-text point-text--regular">
                  Touchpoints
                </div>
              </div>
            </div>
            <div class="d-flex flex-column flex-sm-row align-center align-sm-start mb-2 bigger">
              <v-icon size="22" color="#2b84eb" class="mr-2 ml-2 ml-sm-0 pt-1">
                mdi-cellphone
              </v-icon>
              <div class="d-flex flex-column align-center align-sm-start">
                <div class="metric-score">
                  {{ contact_statistics.preferredDevice || 'N/A' }}
                </div>
                <div class="point-text point-text--regular">
                  Preferred Device
                </div>
              </div>
            </div>
            <div class="d-flex flex-column flex-sm-row align-center align-sm-start mb-2">
              <div class="mr-2 ml-2 ml-sm-0">
                <ProgressCircle :value="contact_statistics && contact_statistics.contactStatistics ? Number.isInteger(contact_statistics.contactStatistics.uniqueOpenRate) ? contact_statistics.contactStatistics.uniqueOpenRate : contact_statistics.contactStatistics.uniqueOpenRate.toFixed(2) : 0" />
              </div>
              <div class="d-flex flex-column align-center align-sm-start">
                <div class="metric-score">
                  {{ contact_statistics && contact_statistics.contactStatistics ? Number.isInteger(contact_statistics.contactStatistics.uniqueOpenRate) ? contact_statistics.contactStatistics.uniqueOpenRate : contact_statistics.contactStatistics.uniqueOpenRate.toFixed(2) : 0 }}%
                </div>
                <div class="point-text point-text--regular">
                  Open Rate
                </div>
              </div>
            </div>
            <div class="d-flex flex-column flex-sm-row align-center align-sm-start mb-2">
              <div class="mr-2 ml-2 ml-sm-0">
                <ProgressCircle :value="contact_statistics && contact_statistics.contactStatistics ? Number.isInteger(contact_statistics.contactStatistics.uniqueClickRate) ? contact_statistics.contactStatistics.uniqueClickRate : contact_statistics.contactStatistics.uniqueClickRate.toFixed(2) : 0" />
              </div>
              <div class="d-flex flex-column align-center align-sm-start">
                <div class="metric-score">
                  {{ contact_statistics && contact_statistics.contactStatistics ? Number.isInteger(contact_statistics.contactStatistics.uniqueClickRate) ? contact_statistics.contactStatistics.uniqueClickRate : contact_statistics.contactStatistics.uniqueClickRate.toFixed(2) : 0 }}%
                </div>
                <div class="point-text point-text--regular">
                  Click Rate
                </div>
              </div>
            </div>
            <div class="d-flex flex-column flex-sm-row align-center align-sm-start mb-2 bigger">
              <v-icon size="22" color="#2b84eb" class="mr-2 ml-2 ml-sm-0 pt-1">
                mdi-content-paste
              </v-icon>
              <div class="d-flex flex-column align-center align-sm-start">
                <div
                  v-if="contact_statistics.contactStatistics"
                  class="metric-score"
                >
                  {{ contentPreferenceTagPlaceholder }}
                </div>
                <div
                  v-else
                  class="metric-score"
                >
                  N/A
                </div>
                <div class="point-text point-text--regular">
                  Preferred Content
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column align-center">
            <div class="title-text mb-1">
              {{ `${monthText} Engagement` }}
            </div>
            <Calendar
              :calendar_info="contact_statistics"
              :current-month="calendarMonth"
              width="220"
              @prev-month="$emit('prev-month')"
              @next-month="$emit('next-month')"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Calendar from "@/views/Contacts/components/DigitalPersona/Calendar";
import ProgressCircle from "@/sharedComponents/ProgressCircle";
import datesMixin from "@/utils/datesMixin.js";
import dayjs from "dayjs";

export default {
  name: "EngagementMetrics",
  components: { Calendar, ProgressCircle },
  mixins: [datesMixin],
  props: {
    contact_details: {
      default: () => {},
    },
    contact_statistics: {
      default: () => {},
    },
    touchpoints: {
      default: () => 0
    },
    calendarMonth: {
      type: Date,
      required: true,
    },
  },
  computed: {
    monthText() {
      return dayjs(this.calendarMonth).format('MMM YYYY');
    },
    contentPreferenceTagPlaceholder() {
      return this.contact_statistics?.contactStatistics?.contentPreferenceTag?.name ?? "-"
    }
  },
};
</script>

<style lang="scss" scoped>
.metric-score {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  color: #2b84eb;
  &--gold {
    color: #eba42b;
  }
}

.statistic {
  flex-basis: 400px;

  > * {
    flex: 1 1 30%;
  }
  .bigger {
    flex-basis: 40%;
  }
}
</style>
