<template>
  <v-container class="mt-3 px-sm-10 px-3 segments-accordion elevation-0" fluid>
    <PageHeader
      header-text="Email Creatives"
      regular-button-text="New creative"
      :regular-button-visible="$store.getters['user/hasWritePermission']"
      @regular-button-click="
        select_creative_type_dialog_opened = true;
        active_creative = {};
      "
    >
      <template #subheader>
        Select and Design Email Messages
      </template>
    </PageHeader>

    <SearchFieldRowWithPagination
      search-field-placeholder="Search for creatives"
      :current-page-number="creatives_page_number"
      :current-page-size="creatives_page_size"
      :count-total="creatives_total"
      :page-sizes="[25, 50, 100]"
      @next-page="get_creatives_next_page"
      @prev-page="get_creatives_prev_page"
      @change-page-size="creatives_change_page_size"
      @search="(search_string) => on_search(search_string)"
    />

    <CreativesTable
      :creatives="creatives"
      @action="handleAction($event)"
      @image-preview="openPreviewImage($event)"
    />

    <v-dialog v-model="clone_dialog" max-width="560px">
      <CloneEmailMessageDialog
        v-if="clone_dialog"
        @dismiss="
          clone_dialog = null;
          clone_message_id = null;
        "
        @save="clone_message"
      />
    </v-dialog>

    <v-dialog
      v-model="select_creative_type_dialog_opened"
      scrollable
      max-width="560px"
    >
      <SelectCreativeTypeDialog
        v-if="select_creative_type_dialog_opened"
        :edit_mode="!!active_creative.id"
        :active_creative="active_creative"
        @dismiss="select_creative_type_dialog_opened = false"
        @save="on_select_creative_type($event)"
        @edit-creative-name="active_creative.name = $event"
      />
    </v-dialog>

    <v-dialog
      v-model="creative_zip_import_dialog_opened"
      scrollable
      max-width="560px"
    >
      <ImportFromZipDialog
        v-if="creative_zip_import_dialog_opened"
        :creative-id="zip_active_creative"
        @dismiss="creative_zip_import_dialog_opened = false"
        @save="on_zip_creative_type($event)"
      />
    </v-dialog>

    <v-dialog v-model="previewImageDialog" scrollable>
      <ImagePreviewDialog
        v-if="previewImageDialog"
        :image-url="previewImageUrl"
        :full-image-url="previewFullImageUrl"
        :name="previewImageName"
        @dismiss="previewImageDialog = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import SearchFieldRowWithPagination from "@/sharedComponents/SearchFieldRowWithPagination";
import CreativesTable from "@/views/Campaigns/components/EmailCreatives/CreativesTable";
import SelectCreativeTypeDialog from "@/views/Campaigns/components/EmailCreatives/SelectCreativeTypeDialog";
import CloneEmailMessageDialog from "@/views/Campaigns/components/EmailCreatives/CloneEmailMessageDialog.vue";
import ImagePreviewDialog from "@/sharedComponents/ImagePreviewDialog";
import ImportFromZipDialog from "@/views/Campaigns/components/EmailCreatives/ImportFromZipDialog";

export default {
  name: "EmailCreativesView",
  metaInfo: {
    title: "Email Creatives",
  },
  components: {
    ImportFromZipDialog,
    SelectCreativeTypeDialog,
    SearchFieldRowWithPagination,
    PageHeader,
    CreativesTable,
    CloneEmailMessageDialog,
    ImagePreviewDialog,
  },
  data() {
    return {
      select_creative_type_dialog_opened: false,
      creative_zip_import_dialog_opened: false,
      email_code_editor_dialog_opened: false,
      preview_creative_dialog_opened: false,
      creatives: [],
      creatives_total: 0,
      creatives_page_number: 1,
      creatives_page_size: 25,
      search_string: null,
      active_creative: {},
      zip_active_creative: null,
      new_creative_name: null,
      clone_dialog: false,
      clone_message_id: null,
      previewImageDialog: false,
      previewImageUrl: null,
      previewFullImageUrl: null,
      previewImageName: null,
    };
  },
  created() {
    this.get_available_creatives();
  },
  methods: {
    openPreviewImage({ name, image }) {
      this.previewImageUrl = image.medium || image.large || image.full || image.small;
      this.previewFullImageUrl = image.full || image.large || image.medium || image.small;
      this.previewImageName = name;
      this.previewImageDialog = !!this.previewImageUrl;
    },
    on_select_creative_type(data) {
      this.edit_creative(data);
    },
    async edit_creative(data) {
      if (this.active_creative.id) {
        const creative = { ...this.active_creative };
        await this.$rest.creative.email.put_resource(
          this.active_creative.id,
          creative
        );
      } else {
        const resp = await this.$rest.creative.email.post_resource({
          name: data.creative_name,
          tagItems: data.tags,
        });

        if (!resp.data) {
          return;
        }

        if (data.selected_content_type === 'zip') {
          this.zip_active_creative = resp.data.id;
          this.creative_zip_import_dialog_opened = true;
        } else {
          // redirect to the edit page
          this.handleAction({
            event: {
              value: "edit",
            },
            item: resp.data,
          });
        }
      }

      this.get_available_creatives();
    },

    async on_zip_creative_type(data) {
      this.handleAction({
        event: {
          value: "edit",
        },
        item: data,
      });
    },

    async clone_message(name) {
      const response = await this.$rest.creative.email.clone_resource(
        this.clone_message_id,
        name
      );
      if (response)
        this.$router.push({
          name: "EditEmailCreative",
          params: {
            id: response.data.id,
          },
        });
    },

    async handleAction(action) {
      if (action.event.value === "delete") {
        await this.$rest.creative.email.put_resource(
          action.item.id,
          {...action.item, isArchived: true},
        );
        await this.get_available_creatives();
      } else if (action.event.value === "edit") {
        this.$router.push({
          name: "EditEmailCreative",
          params: {
            id: action.item.id,
          },
        });
      } else if (action.event.value === "clone") {
        this.clone_dialog = true;
        this.clone_message_id = action.item.id;
      }
    },

    async on_search(search_string) {
      this.search_string = search_string;
      await this.get_available_creatives();
    },

    async creatives_change_page_size(size) {
      this.creatives_page_size = size;
      this.creatives_page_number = 1;
      await this.get_available_creatives();
    },
    async get_creatives_next_page() {
      this.creatives_page_number += 1;
      await this.get_available_creatives();
    },
    async get_creatives_prev_page() {
      this.creatives_page_number -= 1;
      await this.get_available_creatives();
    },
    async get_available_creatives() {
      const resp = await this.$rest.creative.email.get_collection({
        limit: this.creatives_page_size,
        page: this.creatives_page_number,
        name: this.search_string || null,
        sort: ["createdAt:desc"],
        isArchived: false,
        groups: ["tag_creative:get"],
      });
      this.creatives = resp.data.items;
      this.creatives_total = resp.data.totalCount;
    },
  },
};
</script>
