<template>
  <div
    v-if="!loading"
    class="pagination-selector-container universal-choice-selector d-flex align-center justify-sm-center justify-end"
  >
    <v-btn
      v-if="currentPageNumber !== 1"
      class="pagination-button mr-4"
      color="#e5eaed"
      height="35px"
      icon
      @click="$emit('prev-page')"
    >
      <v-icon color="#757885">
        mdi-chevron-left
      </v-icon>
    </v-btn>
    <v-menu
      v-if="pageSizes.length > 1"
      class="pagination-dropdown"
      transition="slide-y-transition"
      bottom
      offset-y
      max-width="200px"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          class="pagination-dropdown-button elevation-0 px-3"
          height="30px"
          dense
          v-bind="attrs"
          v-on="on"
        >
          View {{ currentPageSize }}
          <v-icon right dark>
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>
      <v-list class="field-selector-menu universal-choice-selector-menu">
        <v-list-item-group>
          <v-list-item
            v-for="(item, i) in pageSizes"
            :key="i"
            class="px-6 d-flex justify-center align-center"
            @click="$emit('change-page-size', item)"
          >
            <v-list-item-title>View {{ item }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <span class="pagination-counter ml-2">{{ 1 + (currentPageNumber - 1) * currentPageSize }}-{{
      currentPageNumber * currentPageSize > countTotal
        ? countTotal
        : currentPageNumber * currentPageSize
    }}
      of {{ countTotal.toLocaleString() }}
    </span>
    <v-btn
      v-if="currentPageNumber * currentPageSize < countTotal"
      class="pagination-button ml-2"
      color="#e5eaed"
      height="35px"
      icon
      @click="$emit('next-page')"
    >
      <v-icon color="#757885">
        mdi-chevron-right
      </v-icon>
    </v-btn>
  </div>
  <skeleton-loader-vue
    v-else
    :width="240" :height="35"
    animation="wave"
    rounded :radius="4"
  />
</template>

<script>
export default {
  name: "PaginationButtons",
  props: {
    countFrom: {
      default: () => 1,
      type: Number,
    },
    countTo: {
      default: () => 10,
      type: Number,
    },
    currentPageNumber: {
      default: () => 1,
      type: Number,
    },
    countTotal: {
      default: () => 999,
      type: Number,
    },
    currentPageSize: {
      default: () => 2,
      type: Number,
    },
    pageSizes: {
      default: () => [25, 50, 100],
      type: Array,
    },
    loading: {
      default: false,
      type: Boolean,
    },
  },
  data: () => {
    return {
      selectedCondition: "25",
    };
  },
};
</script>

<style lang="scss" scoped>
.pagination-dropdown-button {
  border-radius: 4px;
  border: solid 1px #dee7ee;
}
.pagination-counter {
  white-space: nowrap;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #241c15;
}
::v-deep {
  .pagination-button {
    border-radius: 4px;
    background-color: #e5eaed;
  }

  button.v-btn--contained {
    border-radius: 4px;
    border: solid 1px #dee7ee !important;
    background-color: white !important;
    span {
      font-family: "Open Sans", sans-serif;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #757885;
      text-transform: none;
    }
  }
}
</style>
