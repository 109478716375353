<template>
  <LoginBase>
    <div style="width: 100%;">
      <FormHead
        :has-error="!!errorMsg"
        :error-message="errorMsg"
      />
      <validation-observer ref="form">
        <v-form>
          <div class="login-text-input-header">
            Your email
          </div>
          <CustomTextInput
            v-model="email"
            class="mb-2 mt--2"
            type="login"
            placeholder=""
            :required="true"
            :email="true"
            @enter="login(email, password)"
          />
          <div class="login-text-input-header">
            Your password
          </div>
          <CustomTextInput
            v-model="password"
            class="mb-2 mt--2"
            type="password"
            placeholder=""
            :required="true"
            :passmin="8"
            autocomplete="off"
            @enter="login(email, password)"
          />
          <v-btn
            id="btn-primary"
            :loading="isLoading"
            dense
            max-height="34px"
            block
            color="primary"
            class="mb-3"
            @click="login(email, password)"
          >
            Log in
          </v-btn>
        </v-form>
      </validation-observer>
      <v-btn
        text
        block
        color="primary"
        @click="$router.push({name: 'PasswordResetRequest'})"
      >
        Forgot password?
      </v-btn>
    </div>
  </LoginBase>
</template>

<script>
import FormHead from "@/views/Login/components/FormHead";
import LoginBase from "@/views/Login/LoginBase";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import datesMixin from "@/utils/datesMixin.js";
import AuthHelper from "@/utils/AuthHelper";

export default {
  name: "LoginPage",
  metaInfo: {
    title: 'Login'
  },
  components: {LoginBase, FormHead, CustomTextInput},
  mixins: [datesMixin],
  data: () => ({
    password: '',
    email: '',
    isLoading: false,
    errorMsg: null,
  }),
  async created() {
    try {
      await this.$rest.user.get_user_details();
      await this.$router.push({name: "DashboardList"});
    } catch (e) {
      // do nothing
    }
  },
  methods: {
    async validate () {
        return await this.$refs.form.validate()
    },
    reset () {
        this.$refs.form.reset()
    },
    resetValidation () {
        this.$refs.form.resetValidation()
    },
    // eslint-disable-next-line no-unused-vars
    async login(login, pass) {
      try {
        this.errorMsg = null;
        this.isLoading = true

        if (await this.validate()) {
          const resp = (await this.$rest.login.authorize(login, pass)).data
          const user_details = (await this.$rest.user.get_user_details()).data;
          localStorage.setItem('user', JSON.stringify(user_details));
          this.$store.commit('user/update_user_info', {
            ...user_details
          });

          if (parseInt(process.env.VUE_APP_TWO_FA_BYPASS) === 0 && !await AuthHelper.isAuthenticated2fa()) {
            return this.$router.push({name: 'PhoneConfirm'});
          }

          this.$store.commit('user/updateJwtExpiration', new Date(resp.expire_date))
          await this.$store.dispatch('user/start_logout_check');
          let redirectPageData = [];

          try {
            redirectPageData = JSON.parse(localStorage.getItem('redirectPage'));
          } catch (e) {
            redirectPageData = null;
          }

          if (localStorage.getItem('redirectPage')
              && localStorage.getItem('redirectPage') !== 'undefined'
              && redirectPageData && redirectPageData.value !== '/') {
            localStorage.removeItem('redirectPage');

            return await this.$router.push(redirectPageData.value)
          } else if (redirectPageData && redirectPageData.expiry < Date.now()) {
            localStorage.removeItem('redirectPage');
          }

          const respAccounts = await this.$rest.account.get_collection({
            limit: 5,
            page: 1,
            lastAccessedDate: true,
          });
          const accountTotalCount = respAccounts.data.totalCount;
          if (accountTotalCount === 1) {
            const firstAccount = respAccounts.data.items[0]
            const selectedAccount = await this.$store.dispatch('user/selectAccount', firstAccount.accountId, firstAccount.logoUrl);

            this.$notifier.success(`Successfully selected account ${selectedAccount.account.name}`)
            await this.$router.push(
                this.$route.query.next
                    ? { path: this.$route.query.next }
                    : {
                      name: "DashboardView",
                      params: { id: this.$store.getters["user/defaultDashboard"] },
                      query: {
                        accid: firstAccount.accountId,
                      },
                    }
            );
          } else {
            await this.$router.push({name: 'Accounts'})
          }
          this.$notifier.success("Successfully logged in.");
        }
        this.isLoading = false
      } catch (e) {
        console.error(e);
        this.isLoading = false
        this.errorMsg = e.response?.data?.message ?? 'The username or password you provided are invalid';
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.login-text-input-header {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: #241c15;
}

#btn-primary {
  background-color: #2b84eb !important;
}
</style>

