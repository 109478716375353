var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._m(0),(!_vm.isLoadingFromLines)?_c('CustomDropdown',{staticClass:"mt--2",attrs:{"placeholder":"Select an Option","value":_vm.configuration.fromLineId,"items":_vm.fromLinesToShow,"item-text":"text","item-value":"id","required":""},on:{"change":function($event){return _vm.$emit('update:configuration', {
          ..._vm.configuration,
          fromLineId: $event,
        })}}}):_c('div',{staticClass:"loading-field-placeholder"},[_c('div',[_vm._v("Loading From Lines...")])])],1),(!_vm.isLoadingFromLines && _vm.configuration.fromLineId === 'SEND_FROM_REP')?_c('div',{staticClass:"mt-7"},[_vm._m(1),_c('CustomDropdown',{attrs:{"value":_vm.configuration.backupFromLineId,"required":"","item-text":"text","item-value":"id","subheader":"Contacts without a rep will receive the email from this option","placeholder":"From Who Else","items":_vm.backupFromLineOptions},on:{"change":function($event){return _vm.$emit('update:configuration', {
          ..._vm.configuration,
          backupFromLineId: $event,
        })}}})],1):_vm._e(),_vm._m(2),_c('CustomTextInput',{staticClass:"mt--2",attrs:{"value":_vm.configuration.subject,"required":"","placeholder":"Subject Line"},on:{"change":function($event){return _vm.$emit('update:configuration', {
        ..._vm.configuration,
        subject: $event,
      })}}}),_c('div',{staticClass:"notification-menu__header mt-7"},[_vm._v(" Preview Text ")]),_c('CustomTextInput',{staticClass:"mt--2",attrs:{"value":_vm.configuration.previewText,"maxlength":150,"placeholder":"Preview Text"},on:{"change":function($event){return _vm.$emit('update:configuration', {
        ..._vm.configuration,
        previewText: $event,
      })}}}),_vm._m(3),(_vm.selectedEmailMessage && _vm.selectedEmailMessage.id)?_c('div',{staticClass:"d-flex align-center justify-center mt-2 mb-5"},[_c('v-img',{staticClass:"mr-3",attrs:{"max-height":"95px","max-width":"95px","contain":"","src":_vm.selectedEmailMessage.imageFileUrls.small}}),_c('div',{staticClass:"ml-3"},[_c('div',{staticClass:"campaigns-table__campaign-header"},[_vm._v(" "+_vm._s(_vm.selectedEmailMessage.name)+" ")]),_c('small',{staticClass:"text--disabled",staticStyle:{"font-size":"11px"}},[_vm._v(" ID: "+_vm._s(_vm.selectedEmailMessage.id)+" ")])])],1):_vm._e(),_c('div',{staticClass:"d-flex justify-center mt-2"},[_c('v-btn',{staticClass:"custom-button custom-button--blue",attrs:{"height":"34px","width":"150px"},on:{"click":function($event){_vm.choose_existing_dialog_opened = true}}},[_vm._v(" Select Content ")])],1),_c('v-switch',{staticClass:"custom-switch",attrs:{"input-value":_vm.configuration.useBestDay,"inset":"","label":"Use Best Day/Time"},on:{"change":function($event){return _vm.$emit('update:configuration', {
        ..._vm.configuration,
        useBestDay: $event,
      })}}}),_c('v-dialog',{attrs:{"scrollable":"","max-width":"900px"},model:{value:(_vm.choose_existing_dialog_opened),callback:function ($$v) {_vm.choose_existing_dialog_opened=$$v},expression:"choose_existing_dialog_opened"}},[(_vm.choose_existing_dialog_opened)?_c('ChooseExistingCampaignDialog',{attrs:{"selected_item_id":_vm.configuration.creativeId},on:{"dismiss":function($event){_vm.choose_existing_dialog_opened = false},"select":(data) => _vm.onSelectCreative(data)}}):_vm._e()],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notification-menu__header"},[_c('span',{staticClass:"field__subheader--required"},[_vm._v("*")]),_vm._v(" From: Who is sending this email? ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notification-menu__header"},[_c('span',{staticClass:"field__subheader--required"},[_vm._v("*")]),_vm._v(" From Line for contacts without a rep ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notification-menu__header mt-7"},[_c('span',{staticClass:"field__subheader--required"},[_vm._v("*")]),_vm._v(" Subject Line ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notification-menu__header mt-7"},[_c('span',{staticClass:"field__subheader--required"},[_vm._v("*")]),_vm._v(" Content ")])
}]

export { render, staticRenderFns }