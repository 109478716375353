<template>
  <v-card class="add-content-popup-card pb-7">
    <v-card-title class="px-6 pt-4 pb-4">
      Import from Zip
    </v-card-title>
    <v-card-text class="pa-4">
      <v-row class="px-0 mx-2 mb-4">
        <v-col cols="12" class="d-flex justify-start flex-wrap pt-2">
          <v-btn
            class="custom-button custom-button--blue px-11 mr-sm-5 mb-4 mb-sm-0"
            style="min-width: 150px"
            height="34px"
            depressed
            :block="$vuetify.breakpoint.xsOnly"
            @click="selectFile"
          >
            {{ uploadFileName ? `File: ${uploadFileName}` : "Choose File" }}
          </v-btn>

          <input
            ref="fileUpload"
            type="file"
            class="d-none"
            accept=".zip"
            @change="onFileSelected"
          >
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="d-flex justify-end align-center pa-0 mx-3">
      <div
        class="simple-text-button button--gray px-9"
        @click="$emit('dismiss')"
      >
        Cancel
      </div>

      <v-btn
        :loading="isLoading"
        class="custom-button custom-button--blue px-15"
        height="34px"
        depressed
        @click="onSave"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: "ImportFromZipDialog",
  components: { },
  props: {
    creativeId: {
      default: false,
      required: true,
    },
  },
  data() {
    return {
      uploadFileName: null,
      file: null,
      isLoading: false,
    };
  },
  methods: {
    onFileSelected(event) {
      const file = event.target.files[0];
      this.file = file;
      this.uploadFileName = file.name;
    },
    async onSave() {
      if (!this.file) {
        return this.$store.commit('snackbar/showMessage', {
          'content': 'Please select a Zip file before importing.',
          'color': 'error',
        });
      }

      try {
        this.isLoading = true;
        const formData = new FormData();
        formData.append('zip_file', this.file);

        const { data } = await this.$rest.creative.email.upload_zip_resource(this.creativeId, formData);
        // return creative type to calling component
        this.$emit("save", {
          id: data.id,
        });
        this.isLoading = false;
        // close this dialog
        this.$emit("dismiss");
      } catch (e) {
        this.isLoading = false;
        const errorResponse = e.response?.data ?? null;

        if (errorResponse) {
          let errors = '';

          if (errorResponse?.errors) {
            errorResponse.errors.forEach(item => {
              errors += (item.error + "\r \n");
            });
          } else if (errorResponse.message) {
            errors = errorResponse.message;
          }

          return this.$store.commit('snackbar/showMessage', {
            'content': errors ? errors : 'Error while trying to upload the file, please try again.',
            'color': 'error',
          });
        }
      }
    },
    selectFile() {
      this.$refs.fileUpload.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.add-content-popup-card {
  border-radius: 5px !important;
}
</style>
