var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mb-5"},[_vm._m(0),(!_vm.isLoadingFromLines)?_c('CustomDropdown',{attrs:{"placeholder":"Select an Option","value":_vm.configuration.fromLineId,"items":_vm.fromLinesToShow,"item-value":"id","item-text":"text"},on:{"change":function($event){return _vm.$emit('update:configuration', {
          ..._vm.configuration,
          fromLineId: $event,
        })}}}):_c('div',{staticClass:"loading-field-placeholder"},[_c('div',[_vm._v("Loading From Lines...")])])],1),(!_vm.isLoadingFromLines && _vm.configuration.fromLineId === 'SEND_FROM_REP')?_c('div',{staticClass:"mb-5"},[_vm._m(1),_c('CustomDropdown',{attrs:{"value":_vm.configuration.backupFromLineId,"required":"","item-text":"text","item-value":"id","subheader":"Contacts without a rep will receive the email from this option","placeholder":"From Who Else","items":_vm.backupFromLineOptions},on:{"change":function($event){return _vm.$emit('update:configuration', {
          ..._vm.configuration,
          backupFromLineId: $event,
        })}}})],1):_vm._e(),_c('SimpleCampaignDetails',{attrs:{"type":"split-test","campaign_config":_vm.get_campaign_config,"campaign":_vm.campaign,"selected_contact_type_key1":_vm.selected_contact_type_key1,"selected_contact_type_key2":_vm.selected_contact_type_key2,"selected_contact_type_key3":_vm.selected_contact_type_key3,"selected_existing_campaign":_vm.selected_existing_campaign,"activate_testing":true,"in_modal":true},on:{"update:campaign_config":function($event){_vm.get_campaign_config=$event},"update:selected_contact_type_key1":function($event){_vm.selected_contact_type_key1=$event},"update:selected_contact_type_key2":function($event){_vm.selected_contact_type_key2=$event},"update:selected_contact_type_key3":function($event){_vm.selected_contact_type_key3=$event},"update:selected_existing_campaign":function($event){_vm.selected_existing_campaign=$event}}}),_c('v-switch',{staticClass:"custom-switch",attrs:{"input-value":_vm.configuration.winnerUsesBestTime,"inset":"","label":"Use Best Day/Time"},on:{"change":function($event){return _vm.$emit('update:configuration', {
        ..._vm.configuration,
        winnerUsesBestTime: $event,
      })}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field__header"},[_c('span',{staticClass:"field__subheader--required"},[_vm._v("*")]),_vm._v(" From: Who is sending this email? ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field__header"},[_c('span',{staticClass:"field__subheader--required"},[_vm._v("*")]),_vm._v(" From Line for contacts without a rep ")])
}]

export { render, staticRenderFns }