var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"mt-3 px-sm-10 px-3",attrs:{"fluid":""}},[_c('PageHeader',{attrs:{"header-text":"Banner Report"},scopedSlots:_vm._u([{key:"subheader",fn:function(){return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"flex-fill"},[_vm._v(" Generate a Banner Report and view statistics and performance for banner engagement. ")]),_c('CustomDateRangePicker',{attrs:{"format":_vm.showDateFormat,"min-date":_vm.minDate,"max-date":_vm.maxDate,"required":true},on:{"day-click":_vm.handleDateClick},model:{value:(_vm.dateRanges),callback:function ($$v) {_vm.dateRanges=$$v},expression:"dateRanges"}})],1)]},proxy:true}])}),_c('validation-observer',{ref:"reportForm"},[_c('v-row',{staticClass:"form-container"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"4"}},[_c('CustomDropdown',{attrs:{"header":"Banner Sources","placeholder":"Select a source","items":_vm.bannerSourcesOptions},model:{value:(_vm.reportPayload.source),callback:function ($$v) {_vm.$set(_vm.reportPayload, "source", $$v)},expression:"reportPayload.source"}})],1)],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"custom-button px-13 mr-2",attrs:{"height":"34px","depressed":"","block":_vm.$vuetify.breakpoint.xsOnly},on:{"click":_vm.reset}},[_vm._v(" Reset ")]),_c('v-btn',{staticClass:"custom-button custom-button--blue px-13",attrs:{"height":"34px","depressed":"","block":_vm.$vuetify.breakpoint.xsOnly},on:{"click":_vm.submit}},[_vm._v(" Generate Report ")])],1)],1),_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(_vm.bannerReport),expression:"bannerReport"}],staticClass:"mt-8",style:({
      height: _vm.dynamic_height ? _vm.dynamic_height + 'px' : 'auto',
    }),attrs:{"fluid":""}},[_c('v-row',{staticClass:"grid-stack grid-stack-4",staticStyle:{"z-index":"7"}},_vm._l((_vm.report_widgets),function(report_widget){return _c('div',{key:'report-widget-' + report_widget.id,class:{
          'grid-stack-item': true,
          'ui-draggable-disabled': true,
          'ui-resizable-disabled': true,
        },attrs:{"id":'report-widget-' + report_widget.id,"gs-x":report_widget.xPosition,"gs-y":report_widget.yPosition,"gs-w":report_widget.width,"gs-h":report_widget.height}},[_c('div',{staticClass:"grid-stack-item-content"},[_c(report_widget.renderComponentName,{tag:"component",staticClass:"item-component",attrs:{"config":report_widget,"data":report_widget.data,"vertical":""}})],1)])}),0)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }