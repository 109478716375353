<template>
  <div class="search-table">
    <v-simple-table>
      <thead>
        <tr class="search-table__header">
          <th>Name</th>
          <th>Score Range</th>
          <th>Created</th>
          <th>Segment</th>
          <th v-if="isPulseAdmin || isPulseUser" />
          <th v-if="isPulseAdmin || isPulseUser" />
        </tr>
      </thead>
      <tbody class="search-table__content">
        <template v-if="segments.length">
          <tr v-for="(item, index) in segments" :key="index" :class="{ disabled: item.deletedAt}">
            <td>{{ item.name }}</td>
            <td>{{ getScoreRange(item) }}</td>
            <td>
              <div :title="getDateWithDayNameAndTime(item.createdAt)">
                {{ getDate(item.createdAt) }}
              </div>
            </td>
            <td>
              {{ item.segment ? item.segment.name || '-' : '-' }}
            </td>
            <template v-if="!item.deletedAt">
              <td v-if="isPulseAdmin || isPulseUser">
                <div
                  class="simple-text-button button--blue"
                  @click="$emit('edit', item)"
                >
                  Edit
                </div>
              </td>
              <td v-if="isPulseAdmin || isPulseUser">
                <div
                  class="simple-text-button button--red"
                  @click="$emit('delete', item.id)"
                >
                  Delete
                </div>
              </td>
            </template>
            <td v-else colspan="2">
              Deleting record
            </td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td colspan="5">
              No segments found. Create one by clicking the button above!
            </td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import datesMixin from "@/utils/datesMixin.js";
import {mapGetters} from "vuex";

export default {
  name: "LeadScoreRulesTable",
  components: {},
  mixins: [datesMixin],
  props: {
    segments: {
      default: () => [],
    },
  },
  data() {
    return {
      actions: []
    };
  },
  computed: {
    ...mapGetters('user', ['isPulseAdmin', 'isPulseUser']),
  },
  methods: {
    getScoreRange(item) {
      if (item.minimumScore === null || item.minimumScore === undefined) {
        return 'Less than or equal to ' + item.maximumScore;
      }
      if (item.maximumScore === null || item.maximumScore === undefined) {
        return 'Greater than or equal to ' + item.minimumScore;
      }

      return item.minimumScore + ' to ' + item.maximumScore;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/contact-info-table.scss";
</style>
