<template>
  <v-container class="mt-3 px-sm-10 px-3" fluid>
    <PageHeader
      header-text="Banner Report"
    >
      <template #subheader>
        <div class="d-flex">
          <div class="flex-fill">
            Generate a Banner Report and view statistics and performance for banner engagement.
          </div>
          <CustomDateRangePicker
            v-model="dateRanges"
            :format="showDateFormat"
            :min-date="minDate"
            :max-date="maxDate"
            :required="true"
            @day-click="handleDateClick"
          />
        </div>
      </template>
    </PageHeader>
    <validation-observer ref="reportForm">
      <v-row class="form-container">
        <v-col cols="12" sm="4" class="py-0">
          <CustomDropdown
            v-model="reportPayload.source"
            header="Banner Sources"
            placeholder="Select a source"
            :items="bannerSourcesOptions"
          />
        </v-col>
      </v-row>
    </validation-observer>
    <v-row class="mt-4">
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          class="custom-button px-13 mr-2"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="reset"
        >
          Reset
        </v-btn>
        <v-btn
          class="custom-button custom-button--blue px-13"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="submit"
        >
          Generate Report
        </v-btn>
      </v-col>
    </v-row>
    <v-container
      v-show="bannerReport"
      fluid
      class="mt-8"
      :style="{
        height: dynamic_height ? dynamic_height + 'px' : 'auto',
      }"
    >
      <v-row class="grid-stack grid-stack-4" style="z-index: 7">
        <div
          v-for="report_widget in report_widgets"
          :id="'report-widget-' + report_widget.id"
          :key="'report-widget-' + report_widget.id"
          :class="{
            'grid-stack-item': true,
            'ui-draggable-disabled': true,
            'ui-resizable-disabled': true,
          }"
          :gs-x="report_widget.xPosition"
          :gs-y="report_widget.yPosition"
          :gs-w="report_widget.width"
          :gs-h="report_widget.height"
        >
          <div class="grid-stack-item-content">
            <component
              :is="report_widget.renderComponentName"
              class="item-component"
              :config="report_widget"
              :data="report_widget.data"
              vertical
            />
          </div>
        </div>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import datesMixin from "@/utils/datesMixin.js";
import PageHeader from "@/sharedComponents/PageHeader";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import LineChart from "@/sharedComponents/charts/LineChart";
import CustomDateRangePicker from "@/sharedComponents/CustomDateRangePicker";
import CountWidget from "@/sharedComponents/widgets/CountWidget";
import LineChartWidget from "@/sharedComponents/widgets/LineChartWidget";
import MultipleLineChartWidget from "@/sharedComponents/widgets/MultipleLineChartWidget";
import CalendarHeatmapWidget from "@/views/Plugins/Google/Widgets/CalendarHeatmapWidget";
import PieChartWidget from "@/sharedComponents/widgets/PieChartWidget";
import DataTableWidget from "@/sharedComponents/widgets/DataTableWidget";
import GeographicHeatmapWidget from "@/views/Dashboards/Widgets/GeographicHeatmapWidget";
import {GridStack} from "gridstack";

const showDateFormat = "MM/DD/YYYY";

export default {
  name: "BannerReportView",
  metaInfo: {
    title: 'Banner Report'
  },
  components: {
    CustomDateRangePicker,
    LineChart,
    CustomDropdown,
    PageHeader,
    CountWidget,
    LineChartWidget,
    MultipleLineChartWidget,
    CalendarHeatmapWidget,
    PieChartWidget,
    DataTableWidget,
    GeographicHeatmapWidget,
  },
  mixins: [datesMixin],
  data: () => ({
    bannerSources: [],
    reportPayload: {
      source: '',
    },
    dateRanges: {
      start: (new Date()).setDate(1),
      end: new Date(),
    },
    minDate: new Date(),
    maxDate: new Date(),
    chartDataType: "impressions",
    bannerReport: null,
    showDateFormat,
    grid: null,
    grid_config: {
      alwaysShowResizeHandle: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ),
      resizable: true,
      column: 4,
      cellHeight: "140px", // minimum size when height of block equals 1. Should be high as much as possible, small value (1px) may cause performance issues
      acceptWidgets: false, // Disable dragging outside widgets
    },
    dynamic_height: 0,
    report_widgets: {
      totalImpressions: {
        id: 1,
        xPosition: 0,
        yPosition: 0,
        widget_id: 1,
        name: "Total Impressions",
        height: 1,
        width: 1,
        componentName: "app.widget.countwidget",
        renderComponentName: "CountWidget",
        account_config: null,
        data: {},
        component: "TotalImpressionsCountWidget",
        configuration: {
          color: "#5aa43b",
        }
      },
      totalClicks: {
        id: 2,
        xPosition: 1,
        yPosition: 0,
        widget_id: 2,
        name: "Total Clicks",
        height: 1,
        width: 1,
        componentName: "app.widget.countwidget",
        renderComponentName: "CountWidget",
        account_config: null,
        data: {},
        component: "TotalClicksCountWidget",
        configuration: {
          color: "#ff5252",
        },
      },
      ctr: {
        id: 3,
        xPosition: 2,
        yPosition: 0,
        widget_id: 3,
        name: "Click Through Rate",
        height: 1,
        width: 1,
        componentName: "app.widget.countwidget",
        renderComponentName: "CountWidget",
        account_config: null,
        data: {},
        component: "TotalClicksCountWidget",
        configuration: {
          color: "#9d52ff",
        },
      },
      totalActions: {
        id: 4,
        xPosition: 3,
        yPosition: 0,
        widget_id: 4,
        name: "Total Recorded Actions",
        height: 1,
        width: 1,
        componentName: "app.widget.countwidget",
        renderComponentName: "CountWidget",
        account_config: null,
        data: {},
        component: "TotalActionsCountWidget",
      },
      multipleLineChart: {
        id: 5,
        widget_id: 5,
        xPosition: 0,
        yPosition: 1,
        height: 3,
        width: 4,
        lineChartHeight: 380,
        componentName: "app.widget.multiplelinechartwidget",
        renderComponentName: "MultipleLineChartWidget",
        data: {},
        component: "BannerLineChartWidget",
        defaultDataType: 'impressions',
        name: '',
      },
      geographicMapImpression: {
        id: 6,
        widget_id: 6,
        xPosition: 0,
        yPosition: 4,
        height: 3,
        width: 2,
        componentName: "app.widget.geographicheatmapwidget",
        renderComponentName: "GeographicHeatmapWidget",
        data: {},
        component: "GeographicMapWidget",
        name: 'Impressions by State',
      },
      geographicMapClicks: {
        id: 7,
        widget_id: 7,
        xPosition: 2,
        yPosition: 4,
        height: 3,
        width: 2,
        componentName: "app.widget.geographicheatmapwidget",
        renderComponentName: "GeographicHeatmapWidget",
        data: {},
        component: "GeographicMapWidget",
        name: 'Clicks by State',
      },
      creativeDataChart: {
        id: 8,
        widget_id: 8,
        xPosition: 0,
        yPosition: 7,
        height: 3,
        width: 2,
        componentName: "app.widget.datatablewidget",
        renderComponentName: "DataTableWidget",
        data: {
          showTotals: true,
          headers: [],
          items: [],
        },
        useTooltip: true,
        component: "CreativeDataWidget",
        name: 'Top Creatives',
        defaultSortBy: ['ctr'],
        defaultSortDesc: [true],
      },
      timeOfDayChart: {
        id: 9,
        widget_id: 9,
        xPosition: 2,
        yPosition: 7,
        height: 3,
        width: 1,
        componentName: "app.widget.calendarHeatmapWidget",
        renderComponentName: "CalendarHeatmapWidget",
        data: [],
      },
      deviceTypeChart: {
        id: 10,
        widget_id: 10,
        xPosition: 3,
        yPosition: 7,
        height: 3,
        width: 1,
        componentName: "app.widget.piechartwidget",
        renderComponentName: "PieChartWidget",
        data: [],
        component: "DeviceTypeChartWidget",
        name: 'Clicks By Device'
      },
    },
  }),
  computed: {
    bannerSourcesOptions() {
      return this.bannerSources.map(source => ({ label: source.name, value: source.id }));
    }
  },
  created() {
    this.loadBannerSources();
    this.minDate = this.subtractMonths(new Date(), 25);
  },
  methods: {
    subtractMonths(date, months) {
      const dateCopy = new Date(date);
      dateCopy.setMonth(dateCopy.getMonth() - months);
      return dateCopy;
    },
    addMonths(date, months) {
      const dateCopy = new Date(date);
      dateCopy.setMonth(dateCopy.getMonth() + months);
      return dateCopy.getTime() > new Date().getTime() ? new Date() : dateCopy;
    },
    init_grid() {
      this.grid = GridStack.init(this.grid_config);
      this.grid.enableMove(false, false);
      this.grid.enableResize(false, false);
      window.grid = this.grid;

      setTimeout(this.calculateDynamicHeight.bind(this), 500);
    },
    calculateDynamicHeight() {
      // find lowest widget
      const domWidgets = document.querySelectorAll(".grid-stack-item");
      let bottomHeight = 0;
      for (const domWidget of domWidgets) {
        const bottomOfWidget = domWidget.offsetTop + domWidget.offsetHeight;
        if (bottomOfWidget > bottomHeight) {
          bottomHeight = bottomOfWidget;
        }
      }

      this.dynamic_height = bottomHeight > 0 ? bottomHeight + 50 : 0;
    },
    loadBannerSources() {
      this.$rest.source.get_collection({ islinkedBannerCreative: true })
        .then(result => {
          this.bannerSources.push({ name: 'All Banner Sources', id: null });
          this.bannerSources.push(...result.data.items);
        });
    },
    async submit() {
      const reportData = {};

      if (this.reportPayload?.source) {
        reportData.source = this.reportPayload.source;
      }
      if (this.dateRanges?.start && this.dateRanges?.end) {
        reportData.startDate = this.getSystemDateFormat(this.dateRanges.start);
        reportData.endDate = this.getSystemDateFormat(this.dateRanges.end);
      }

      this.$rest.report.getBannerReport(reportData).then(result => {
        const data = result.data;

        this.$set(this, 'bannerReport', true);
        this.init_grid();

        this.report_widgets.totalActions.data = {count: (data.totals?.actions ?? 0)};
        this.report_widgets.totalImpressions.data = {count: (data.totals?.impressions ?? 0)};
        this.report_widgets.totalClicks.data = {count: (data.totals?.clicks ?? 0)};
        this.report_widgets.ctr.data = {count: (data.totals?.ctr ?? 0.000) + '%'};

        const impressionData = [];
        const clickData = [];
        const ctrData = [];
        const labels = [];
        let cnt = 1;
        let colorPreset = [
          "#B22FBE",
          "#4BA87A",
          "#8DA532",
          "#DC698F",
          "#DBA064",
        ];

        for (const sourceName in data.daily_data) {
          if (cnt > 5) {
            cnt = 1;
          }
          impressionData[sourceName] = {
            'label': sourceName,
            'borderColor': colorPreset[cnt],
            'ptBgColor': colorPreset[cnt],
            "data": []
          };

          clickData[sourceName] = {
            'label': sourceName,
            'borderColor': colorPreset[cnt],
            'ptBgColor': colorPreset[cnt],
            "data": []
          };

          ctrData[sourceName] = {
            'label': sourceName,
            'borderColor': colorPreset[cnt],
            'ptBgColor': colorPreset[cnt],
            "data": []
          };

          cnt = cnt + 1;

          for (const date in data.daily_data[sourceName]) {
            const year = date.slice(0, 4);
            const month = parseInt(date.slice(5, 7)) - 1;
            const day = date.slice(8, 11);
            const d = new Date(year, month, day)

            if (labels.includes(d.toLocaleString('default', {month: 'short'}) + " " + d.getDate()) === false) {
              labels.push(d.toLocaleString('default', {month: 'short'}) + " " + d.getDate());
            }

            impressionData[sourceName]['data'].push(data.daily_data[sourceName][date]?.impressions ?? 0);
            clickData[sourceName]['data'].push(data.daily_data[sourceName][date]?.clicks ?? 0);
            ctrData[sourceName]['data'].push(data.daily_data[sourceName][date]?.ctr ?? 0);
          }
        }

        this.report_widgets.multipleLineChart.data = {
          impressions: {
            stat: data.totals?.impressions?.toLocaleString() ?? 0,
            label: "Impressions",
            value: "impressions",
            chartData: {
              data: impressionData,
              label: labels
            },
            ptBgColor: '#3498db',
            borderColor: '#3498db',
          },
          clicks: {
            stat: data.totals?.clicks?.toLocaleString() ?? 0,
            label: "Clicks",
            value: "clicks",
            chartData: {
              data: clickData,
              label: labels
            },
            ptBgColor: '#e74c3c',
            borderColor: '#e74c3c',
          },
          ctr: {
            stat: data.totals?.ctr?.toLocaleString() ?? 0,
            label: "Click Through Rate",
            value: "ctr",
            chartData: {
              data: ctrData,
              label: labels
            },
            ptBgColor: '#9d52ff',
            borderColor: '#9d52ff',
          },
        };

        const timeOfDayData = [];
        if (Object.keys(data.time_of_day).length > 0) {
          Object.keys(data.time_of_day).forEach(wDay => {
            Object.keys(data.time_of_day[wDay]).forEach(hour => {
              timeOfDayData.push({
                dimension: {
                  dayOfWeekName: wDay,
                  hour,
                },
                metric: {
                  sessions: data.time_of_day[wDay][hour],
                },
              });
            })
          })
        }

        this.report_widgets.timeOfDayChart.data = timeOfDayData;

        this.report_widgets.deviceTypeChart.data = data.device_type?.map((deviceTypeStat) => {
          return {
            total: deviceTypeStat.clicks,
            label: deviceTypeStat.deviceType,
          };
        });

        const totalImpressions = data.creative_data?.reduce((partialSum, item) => {
          const val = parseInt(item.impressions);
          return partialSum + val;
        }, 0);
        const totalClicks = data.creative_data?.reduce((partialSum, item) => {
          const val = parseInt(item.clicks);
          return partialSum + val;
        }, 0);
        const avgCTR = totalImpressions > 0
          ? ((totalClicks / totalImpressions) * 100).toPrecision(3).toLocaleString()
          : 0;

        this.report_widgets.creativeDataChart.data = {
          showTotals: true,
          headers: [
            { text: 'Source', value: 'source_name' },
            { text: 'Creative', value: 'banner_creative_name' },
            {
              text: 'Impressions',
              value: 'impressions',
              canTotal: true,
              totalValue: totalImpressions.toLocaleString()
            },
            {
              text: 'Clicks',
              value: 'clicks',
              canTotal: true,
              totalValue: totalClicks.toLocaleString()
            },
            {
              text: 'CTR',
              value: 'ctr',
              canTotal: true,
              totalValue: avgCTR + '%',
              sort: (a, b) => {
                let aNum = parseFloat(a.replace('%', ''));
                let bNum = parseFloat(b.replace('%', ''));
                return aNum - bNum;
              }
            },
          ],
          items: data.creative_data?.map((stat) => {
            return {
              ...stat,
              impressions: parseInt(stat.impressions).toLocaleString(),
              clicks: parseInt(stat.clicks).toLocaleString(),
              ctr: stat.ctr + '%',
            }
          }),
        };

        const stateImpressionData = [];
        const stateClicksData = [];
        for (const state in data.states_data) {
          stateImpressionData.push({
            state,
            count: data.states_data[state]?.impressions ?? 0,
          });
          stateClicksData.push({
            state,
            count: data.states_data[state]?.clicks ?? 0,
          });
        }
        this.report_widgets.geographicMapImpression.data = stateImpressionData;
        this.report_widgets.geographicMapClicks.data = stateClicksData;
      });
    },
    reset() {
      this.reportPayload = {};
      this.dateRanges = {
        start: (new Date()).setDate(1),
        end: new Date(),
      };
      this.bannerReport = null;
    },
    handleDateClick(event) {
      this.minDate = this.subtractMonths(event.date, 25);
      this.maxDate = this.addMonths(event.date, 3);
    }
  },
}
</script>

<style lang="scss" scoped>
@import url("../../../../node_modules/gridstack/dist/gridstack-extra.css");

.grid-stack {
  >.grid-stack-item {
    height: 160px;

    >.grid-stack-item-content {
      padding: 5px;
      height: 100%;
    }
  }
  .item-component {
    height: 100%;
  }
}
</style>
