var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isDisplayable)?_c('v-expansion-panel',[(!_vm.hasChildren(_vm.folder))?_c('div',{staticClass:"folder-block d-flex align-center",class:[
      _vm.paddingByLevel,
      _vm.isSelectedFolder(_vm.folder) ? 'folder-block--selected' : ''
    ],on:{"click":function($event){return _vm.$emit('select-folder', _vm.folder)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#66788e","size":"14"}},[_vm._v(" mdi-folder-outline ")]),_c('div',[_vm._v(_vm._s(_vm.displayFolderName))]),_c('v-spacer'),(_vm.isSelectedFolder(_vm.folder))?_c('v-icon',{staticClass:"ml-2",attrs:{"color":"#2b84eb","size":"16"}},[_vm._v(" mdi-check ")]):_vm._e()],1):_c('v-expansion-panel-header',{staticClass:"actions-panel__header-outer",class:[
      _vm.paddingByLevel,
      !_vm.hasChildren(_vm.folder) ? 'cursor-auto' : '',
    ],attrs:{"hide-actions":""},on:{"click":function($event){return _vm.$emit('select-folder', _vm.folder)}}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"#66788e","size":"16"}},[_vm._v(" mdi-chevron-right ")]),_c('div',[_vm._v(_vm._s(_vm.displayFolderName))])],1)]),_c('v-expansion-panel-content',{staticClass:"actions-panel__content-outer"},_vm._l((_vm.folder_structure),function(subFolder,index){return _c('FolderItem',{key:index,attrs:{"readonly":!Object.keys(subFolder).length,"folder":subFolder,"folder_structure":_vm.folder_structure,"selected_folder":_vm.selected_folder,"selected_folder_name":_vm.selected_folder_name,"folder-files":_vm.folderFiles,"folder-name-rule":_vm.folder,"parent-level":_vm.level},on:{"select-folder":(childSubFolder) => _vm.$emit('select-folder', childSubFolder)}})}),1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }