<template>
  <div class="custom-text-input">
    <div
      v-if="header || percentage"
      class="d-flex justify-space-between align-center flex-nowrap"
    >
      <div
        v-if="header"
        :class="headerClass || 'field__subheader'"
      >
        {{ header }}
      </div>
      <div
        v-if="percentage && $vuetify.breakpoint.xs"
        class="field__percentage"
      >
        {{ percentage }}
      </div>
    </div>
    <vue-tel-input
      v-model="phone"
      :auto-format="true"
      :default-country="'us'"
      :preferred-countries="['us']"
      :valid-characters-only="true"
      :dropdown-options="dropdownOptions"
      :class="{'is-invalid': phoneError}"
      @country-changed="onInputTel"
      @validate="validateTel"
      @input="formatPhone"
      @focus="formatPhone"
    />
    <div v-if="phoneError" class="error-message">
      {{ phoneError }}
    </div>
  </div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input';

export default {
  name: "CustomTelInternationalInput",
  components: {
    VueTelInput,
  },
  props: {
    header: {
      default: () => {},
      type: String,
    },
    headerClass: {
      default: () => {},
      type: String,
    },
    placeholder: {
      default: () => {},
      type: String,
    },
    maxlength: {
      default: () => {},
      type: [String, Number],
    },
    minlength: {
      default: () => {},
      type: [String, Number],
    },
    passmin: {
      default: () => null,
      type: Number,
    },
    required: {
      default: () => false,
      type: Boolean,
    },
    email: {
      default: () => false,
      type: Boolean,
    },
    regexPhone: {
      default: () => false,
      type: Boolean,
    },
    password_to_compare: {
      default: () => "",
      type: String,
    },
    percentage: {
      default: () => null,
      type: String,
    },
    value: {
      default: () => null,
      type: [String, Number],
    },
    type: {
      default: () => null,
      type: [String, Number],
    },
    hint: {
      default: () => "",
      type: String,
    },
    autocomplete: {
      default: "on",
      type: String,
    },
  },
  data() {
    return {
      phone: null,
      phoneError: null,
      phoneObj: {},
      dropdownOptions: {
        showDialCodeInList: true,
        showFlags: true,
      },
    }
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.$set(this, 'phone', this.value);
      },
    },
  },
  created() {
    if (this.value) {
      this.$set(this, 'phone', this.value);
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onInputTel(obj) {
      if (!this.phone || this.phone.length <= 4) {
        this.phone = '+' + obj.dialCode;
      }
      if (this.phoneObj && this.phoneObj.nationalNumber) {
        this.phone = '+' + obj.dialCode + this.phoneObj.nationalNumber;
      }
      if (obj && obj.iso2) {
        this.phoneObj.country = {"iso2": obj.iso2};
      }
      this.phoneObj.number = this.phone;
      this.$emit("change", this.phoneObj);
    },
    validateTel(obj) {
      this.phoneObj = obj;
      if (obj && obj.valid === false) {
        this.phoneError = 'Phone number is invalid.';
      } else {
        this.phoneError = null
      }
      this.$emit("change", this.phoneObj);
    },
    formatPhone(val) {
      if (val && val[0] !== '+') {
        this.phone = '+' + val;
      }

      this.phoneObj.number = this.phone
      this.$emit("change", this.phoneObj);
    },
  }
};
</script>

<style lang="scss">
.custom-text-input {
  fieldset {
    border-radius: 4px !important;
    border: solid 1px #dee7ee !important;
    background-color: #ffffff !important;
  }
  input {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #241c15;
  }
  .v-input__slot {
    max-height: 44px !important;
    min-height: 44px !important;
    height: 44px !important;
  }
  .v-text-field__details {
    min-height: auto;

    .v-messages {
      min-height: auto;
    }
  }
}
.field {
  &__header {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #241c15;
  }

  &__percentage {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #2b84eb;
  }

  &__subheader {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    line-height: normal;
    color: #66788e;

    &--small {
      font-family: "Open Sans", sans-serif;
      font-size: 11px;
      line-height: normal;
      color: #66788e;
    }
  }

  &__number {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: normal;
    color: #2b84eb;

    &__subheader {
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      color: #66788e;
      padding-bottom: 10px;
    }
  }
}
.vue-tel-input {
  height: 44px;
  margin-top: 16px;
}
.error-message {
  color: red;
  font-size: 12px;
}
.is-invalid {
  border-color: red !important;
}
</style>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>