var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"field__header pa-0"},[_vm._v(" Select delay type ")]),_c('v-radio-group',{staticClass:"custom-field-radio-group mb-7",attrs:{"value":_vm.configuration.type,"row":""},on:{"change":function($event){return _vm.onTypeChange($event)}}},[_c('v-radio',{attrs:{"value":"standard","label":"Standard"}}),_c('v-radio',{attrs:{"value":"specific","label":"Specific"}})],1),(_vm.configuration.type === 'specific')?_c('div',[_c('div',{staticClass:"notification-menu__header"},[_vm._v(" What date & time should the contact be delayed until? ")]),_c('CustomDateAndTimePicker',{attrs:{"datetime":_vm.configuration.specificDateTime,"placeholder":"Pick date and time","allowed_minutes":[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]},on:{"date-input":function($event){_vm.date = $event;
        _vm.updateDatetime()},"time-input":function($event){_vm.time = $event;
        _vm.updateDatetime()}}}),_c('div',{staticClass:"notification-menu__header"},[_vm._v(" What is the time zone for the date & time above? ")]),_c('CustomDropdown',{staticClass:"mb-3",attrs:{"value":_vm.configuration.specificTimezone,"items":_vm.timeZones,"item-text":"text","item-value":"value"},on:{"change":_vm.handleTimezoneChange}})],1):_vm._e(),(_vm.configuration.type === 'specific')?_c('hr',{staticClass:"mb-5"}):_vm._e(),_c('div',{staticClass:"notification-menu__header"},[_vm._v(" How long should the contact be delayed? ")]),_c('v-row',{staticClass:"pt-0 mb-3"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"2"}},[_c('CustomTextInput',{attrs:{"value":_vm.configuration.numberOfUnits,"type":"number"},on:{"input":function($event){return _vm.$emit('update:configuration', {
            ..._vm.configuration,
            numberOfUnits: $event,
          });}}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('CustomDropdown',{attrs:{"value":_vm.configuration.unitOfTime,"items":_vm.delayUnits},on:{"input":function($event){return _vm.$emit('update:configuration', {
            ..._vm.configuration,
            unitOfTime: $event,
          })}}})],1)],1),(_vm.configuration.type === 'specific')?_c('div',{staticClass:"comment-text"},[_vm._v(" The contact will be delayed by this much time if they reach this action after the date specified above. ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }