import Vue from "vue";
import Router from "vue-router";
import VueMeta from "vue-meta";
import store from "../store/index";
import { publicRoutes, protectedRoutes } from "./routes";
import { pluginRoutes } from "./pluginRoutes";
import AuthHelper from "../utils/AuthHelper";

Vue.use(Router);
Vue.use(VueMeta);

const handleNavigation = (method) => {
  const originalMethod = Router.prototype[method];
  Router.prototype[method] = function(location, onResolve, onReject) {
    if (onResolve || onReject) return originalMethod.call(this, location, onResolve, onReject);
    return originalMethod.call(this, location).catch((err) => {
      if (Router.isNavigationFailure(err, Router.NavigationFailureType.redirected)) {
        return err;
      }
      return Promise.reject(err);
    });
  };
};

handleNavigation('push');
handleNavigation('replace');

let router = new Router({
  mode: "history",
  routes: [
    ...publicRoutes,
    ...protectedRoutes,
    ...pluginRoutes,
    {
      path: "*",
      name: "PageNotFound",
      component: () => import("@/views/PageNotFound"),
      meta: { protected: true },
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

async function handleAuthenticatedRoutes(to, from) {
  if (parseInt(process.env.VUE_APP_TWO_FA_BYPASS) === 0) {
    const isTwoFaRoute = to.name === 'PhoneConfirm' || to.name === 'PhoneCodeConfirm';
    const isTwoFaAuthenticated = await AuthHelper.isAuthenticated2fa();

    if (isTwoFaRoute && isTwoFaAuthenticated) {
      return "Accounts";
    }

    if (!isTwoFaRoute && !isTwoFaAuthenticated) {
      return from.path === '/phone/code/validation' ? "PhoneCodeConfirm" : "PhoneConfirm";
    }
  }

  if (from.path === '/' && to.path === '/') {
    return "Accounts";
  }

  return false;
}

function handleUnauthenticatedRoutes(to) {
  if (to.fullPath !== undefined && !["Accounts", "Login", "Logout", "PhoneConfirm", "PhoneCodeConfirm"].includes(to.name) && to.path !== '/') {
    setRedirectPage(to.fullPath);
  } else {
    localStorage.removeItem("redirectPage");
  }

  if (to.name !== "Login" && !isPublicRoute(to)) {
    return "Login";
  }

  return false
}

function setRedirectPage(fullPath) {
  const date = new Date();
  date.setUTCHours(date.getUTCHours() + 3);
  const item = { value: fullPath, expiry: date.getTime() };
  localStorage.setItem("redirectPage", JSON.stringify(item));
}

router.beforeEach(async (to, from, next) => {
  if (!store.getters["user/user_info"]?.id && localStorage.getItem('user')) {
    const user_details = JSON.parse(localStorage.getItem('user'));
    store.commit('user/update_user_info', {
      ...user_details
    });
  }

  // The user went to a new page, lets update the last activity time in case there's no API calls on the page
  store.commit('user/updateLastActivityDateTime', new Date());

  if (handleHashRedirect(to, next)) return;

  if (isPublicRoute(to)) {
    /*eslint no-unused-vars: ["error", { "ignoreRestSiblings": true }]*/
    const { accid, ...noAccQuery } = to.query;
    return next({ query: noAccQuery });
  }

  if (await AuthHelper.isAuthenticated()) {
    let nextRoute = await handleAuthenticatedRoutes(to, from);

    if (nextRoute) {
      return next({ name: nextRoute })
    }
  } else {
    let nextRoute = handleUnauthenticatedRoutes(to);

    if (nextRoute) {
      return next({ name: nextRoute })
    }
  }

  await handleAccountSelection(to);

  handleProtectedRoutes(to, from, next);
});

function hasAccQuery(route) {
  return !!route?.query?.accid;
}

function handleHashRedirect(to, next) {
  if (to.hash && to.hash.startsWith("#!")) {
    return next({ path: to.hash.replace("#!", "/"), query: to.query });
  }
  return false;
}

function isPublicRoute(to) {
  return to.meta.public;
}

async function handleAccountSelection(to) {
  const vuexAccount = store.getters["user/account"];
  const localStorageAccountId = JSON.parse(localStorage.getItem("account"))?.accountId;
  const queryAccountId = to?.query?.accid;

  let accountToLoad = queryAccountId || localStorageAccountId;

  if (accountToLoad && (!vuexAccount?.accountId || vuexAccount.accountId !== accountToLoad)) {
    await selectAccount(accountToLoad);
  }
}

async function selectAccount(accountId) {
  localStorage.removeItem("account");
  localStorage.removeItem("defaultDashboard");
  store.commit("user/update_selected_account", undefined);

  const selectedAccount = await store.dispatch('user/selectAccount', accountId);
  store.commit("snackbar/showMessage", { content: `Successfully selected account ${selectedAccount.account.name}`, color: "success" });
}

function handleProtectedRoutes(to, from, next) {
  const vuexAccountId = store.getters["user/account"]?.accountId;

  if (to.meta.protected) {
    if (to.meta.account_needed && !vuexAccountId && to.name !== "Accounts" && to.name !== "PhoneConfirm") {
      return next({ name: "Accounts", query: { next: to.path } });
    }

    if (to.meta.private && to.meta.private.length > 0 && !to.meta.private.some(role => store.getters['user/activeRoles'].includes(role))) {
      return next({ name: "Accounts", query: { next: to.path } });
    }

    if (vuexAccountId && !hasAccQuery(to) && to.name !== "Accounts") {
      return next({ name: to.name, params: to.params, query: { ...to.query, accid: vuexAccountId } });
    }
  }

  if (!hasAccQuery(to) && hasAccQuery(from) && to.name !== "Accounts") {
    return next({ name: to.name, params: to.params, query: { ...to.query, accid: from.query.accid } });
  } else {
    next();
  }
}


export default router;