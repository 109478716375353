<template>
  <v-container class="manager-user my-profile mt-5 px-10" fluid>
    <PageHeader header-text="My Profile" class="mb-7 flex-grow-0">
      <template #subheader>
        <v-row>
          <v-col cols="8">
            <div>Edit your profile information</div>
          </v-col>
          <v-col cols="4">
            <div>Last login: {{ getDateWithMonthName(user.lastLogin) }} </div>
          </v-col>
        </v-row>
      </template>
    </PageHeader>

    <div class="flex-grow-1 d-flex flex-column">
      <div>
        <validation-observer ref="textInput">
          <v-row class="py-0">
            <v-col cols="12" sm="4" class="py-0">
              <CustomTextInput
                v-model="user.firstName"
                :maxlength="150"
                required
                header="First Name"
                placeholder="Your first name"
                autocomplete="new"
              />
            </v-col>
            <v-col cols="12" sm="4" class="py-0">
              <CustomTextInput
                v-model="user.lastName"
                required
                :maxlength="150"
                header="Last Name"
                placeholder="Your last name"
                autocomplete="new"
              />
            </v-col>
            <v-col cols="12" sm="4" class="py-0">
              <CustomTextInput
                v-model="user.email"
                required
                email
                :maxlength="150"
                header="Email Address"
                placeholder="Your email"
                hint="Hint: Manage your profile picture at https://gravatar.com"
                autocomplete="new"
              />
            </v-col>
          </v-row>

          <v-row class="py-0">
            <v-col cols="12" sm="4" class="py-0">
              <CustomTelInternationalInput
                v-model="user.phoneNumber"
                regex-phone
                :minlength="6"
                :maxlength="14"
                header="Phone Number"
                header-class="field__header"
                placeholder="Your phone number"
                @change="(data) => changeTel(data)"
              />
            </v-col>
          </v-row>

          <v-divider class="mt-8 mb-8" />

          <!-- password -->
          <div class="field__header mb-8">
            Password Reset
          </div>
          <v-row>
            <v-col cols="12" sm="4" class="py-0">
              <CustomTextInput
                v-model="user.plainPassword"
                type="password"
                :maxlength="150"
                :passmin="8"
                header="Enter New Password"
                placeholder="Enter your password"
                autocomplete="new-password"
              />
            </v-col>
            <v-col cols="12" sm="4" class="py-0">
              <CustomTextInput
                v-model="user.password_confirm"
                type="password"
                :maxlength="150"
                :passmin="8"
                :password_to_compare="user.plainPassword"
                :required="!!user.plainPassword"
                header="Confirm New Password"
                placeholder="Confirm your password"
                autocomplete="new-password"
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
              class="py-0"
              :class="{
                'password--weak': password_strength === 'Weak',
                'password--medium': password_strength === 'Medium',
                'password--strong': password_strength === 'Strong',
              }"
            >
              <div class="d-flex align-center justify-space-between">
                <div class="field__header mb-1">
                  Password Strength
                </div>
                <div
                  v-if="user.plainPassword && user.plainPassword.length > 0"
                  class="password-strength mb-1"
                >
                  {{ password_strength }}
                </div>
              </div>
              <div class="password-strength-bar mt-7">
                <v-progress-linear
                  background-color="#dee7ee"
                  :color="password_strength_color"
                  :value="password_strength_points"
                  rounded
                  height="8"
                />
              </div>
            </v-col>
          </v-row>
        </validation-observer>
      </div>

      <div class="flex-grow-1 d-flex justify-end align-end flex-nowrap mt-12 mb-15">
        <v-btn
          class="custom-button custom-button--blue px-13"
          height="34px"
          depressed
          width="150px"
          :block="$vuetify.breakpoint.xsOnly"
          @click="save_user"
        >
          Save
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomTelInternationalInput from "@/sharedComponents/CustomTelInternationalInput";
import datesMixin from "@/utils/datesMixin.js";

export default {
  name: "MyProfile",
  metaInfo: {
    title: 'My Profile'
  },
  components: {
    PageHeader,
    CustomTextInput,
    CustomTelInternationalInput,
  },
  mixins: [datesMixin],
  data() {
    return {
      user: {
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        countryCode: null,
        plainPassword: null,
        password_confirm: null,
      },
    };
  },
  computed: {
    password_strength() {
      if (this.user.plainPassword?.length >= 8) {
        const veryStrongRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{14,}$/gm;
        const strongRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm;
        const mediumRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[a-z]).{8,}$/gm;

        if (veryStrongRegex.exec(this.user.plainPassword)) return "Very Strong";
        else if (strongRegex.exec(this.user.plainPassword)) return "Strong";
        else if (mediumRegex.exec(this.user.plainPassword)) return "Medium";
        return "Weak";
      }
      return null;
    },
    password_strength_points() {
      if (this.password_strength === "Very Strong") return 100;
      else if (this.password_strength === "Strong") return 75;
      else if (this.password_strength === "Medium") return 50;
      else if (this.password_strength === "Weak") return 25;
      return 0;
    },
    password_strength_color() {
      if (this.password_strength === "Weak") return '#de2828';
      else if (this.password_strength === "Medium") return '#fecf33';
      return '#1f9f43';
    },
  },
  async created() {
    const user = (await this.$rest.user.get_user_details()).data;
    this.user = {
      ...this.user,
      ...user
    }
  },
  methods: {
    async save_user() {
      try {
        if (await this.validate()) {
          await this.$rest.user.put_resource({ ...this.user })
          this.$store.commit("user/update_user_info", { ...this.user });
          let user_details = {};
          if (localStorage.getItem('user')) {
            user_details = JSON.parse(localStorage.getItem('user'));
          }
          user_details = {
            ...user_details,
            ...this.user,
          }
          localStorage.setItem('user', JSON.stringify(user_details));
          this.$store.commit('snackbar/showMessage', {
            color: "success",
            content: "User was saved successfully.",
          })
        }
      } catch (e) {
        console.error(e);
      }
    },

    async validate() {
      return await this.$refs.textInput.validate();
    },
    reset() {
      this.$refs.textInput.reset();
    },
    resetValidation() {
      this.$refs.textInput.resetValidation();
    },
    changeTel(obj) {
      if (obj) {
        if (obj.number) {
          this.user.phoneNumber = obj.number;
        }
        if (obj.country && obj.country.iso2) {
          this.user.countryCode = obj.country.iso2;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.my-profile {
  min-height: calc(100vh - 78px);
  display: flex;
  flex-direction: column;
}
</style>
