<template>
  <div class="notification-menu__header">
    <span class="field__subheader--required" />
    Label: {{ configuration.name }}
  </div>
</template>

<script>
    export default {
        name: "ActionEjectConfiguration",
        props: {
            configuration: {
                type: Object,
                required: true,
            },
        },
        created() {
          this.$emit('update:configuration', {
            ...this.configuration,
          });
        },
    }
</script>
